import { memo, useMemo } from "react"
import { LinkBox, LinkOverlay, Box, Text, HStack, Tag } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import { CustomLink } from "@components/Link"
import { CustomImage } from "@components/Image"
import { useDate } from "@app/hooks/useDate"
import { useSanityImageUrl } from "@app/hooks/useSanity"

type ArticleCardProps = {
  article: GatsbyTypes.SanityArticle
  handleTrackingClick?: () => void
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article, handleTrackingClick }) => {
  const { urlResolver } = useRoutes()
  const { getDayMonthLabel } = useDate()
  const { getSanityImageUrl } = useSanityImageUrl()

  const articleItem = useMemo(
    () => ({
      ...article,
      link: urlResolver(article),
      image: {
        alt: article?.image?.alt || "",
        src: article?.image?.asset?.url ? getSanityImageUrl(article?.image?.asset?.url, 440, 325) : article?.image?.src || "",
      },
      publishedAt: article?.attributes?.publishedAt || "",
      category: (!!article?.categories?.length && article?.categories?.[0]?.title) || "",
    }),
    [article, urlResolver, getSanityImageUrl]
  )

  const { dayMonthLabel } = useMemo(() => getDayMonthLabel(articleItem?.publishedAt), [getDayMonthLabel, articleItem?.publishedAt])

  return (
    <LinkBox as="article" overflow="hidden" w="full">
      <Box mb={[4, 6]}>
        <CustomImage src={articleItem?.image?.src} alt={articleItem?.image?.alt} width="full" aspectRatio="article" loading="lazy" />
      </Box>

      <HStack justifyContent="space-between" spacing={7} alignItems="flex-start">
        <Box>
          <LinkOverlay
            as={CustomLink}
            to={articleItem?.link.url}
            title={articleItem?.link.title || articleItem?.title}
            onClick={handleTrackingClick}
          >
            <Text fontSize={["md"]} lineHeight="24px" noOfLines={2} textAlign="left">
              {articleItem?.category && (
                <Text as="span" fontWeight={700} fontSize="inherit">
                  {articleItem?.category}
                  {" — "}
                </Text>
              )}
              {articleItem?.title}
            </Text>
          </LinkOverlay>
        </Box>
        {dayMonthLabel && <Tag variant="date">{dayMonthLabel}</Tag>}
      </HStack>
    </LinkBox>
  )
}

const MemoArticleCard = memo(ArticleCard)
export { MemoArticleCard as ArticleCard }
