import { memo, useMemo } from "react"
import { Box, Heading } from "@chakra-ui/react"
import { ArticleCard } from "./ArticleCard"
import { SearchBar } from "@components/Search/SearchBar"
import { SelectableTags } from "@components/SelectableTags"
import { Listing } from "@components/Listing/Listing"
import { useListingArticles } from "@app/hooks/useListingArticles"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/pages/articles"
import type { PageProps } from "@root/types/global"

const ArticleListHeaderChildren: React.FC = ({
  page,
  handleSearchChange,
  searchTerm,
  handleSearchSubmit,
  articleCategoryTags,
  selectedTag,
  handleTagClick,
}: any) => (
  <Box mt={[4, 4, 7]}>
    <SearchBar
      placeholder={page?.additionalSearchBarPlaceholder}
      bgColor="brand.offWhite"
      handleChange={handleSearchChange}
      value={searchTerm}
      fontSize={["sm", "md"]}
      handleSubmit={handleSearchSubmit}
    />
    <Box mt={8}>
      <SelectableTags tags={articleCategoryTags} selectedTag={selectedTag} handleClick={handleTagClick} />
    </Box>
  </Box>
)

const ArticleListAboveGridChildren = ({ submittedSearchTerm }: any) =>
  submittedSearchTerm ? (
    <Heading as="p" size="h4" mb={4}>
      Search Results for &lsquo;{submittedSearchTerm}&rsquo;
    </Heading>
  ) : null

const ArticleListGridChildren = ({ filteredItems }: any) =>
  filteredItems?.map((article: GatsbyTypes.SanityArticle) => <ArticleCard key={article?._id?.toString()} article={article} />)

const ArticleListing: React.FC<PageProps<Props>> = ({ page, articles, articleCategories }) => {
  const {
    filteredItems,
    submittedSearchTerm,
    handleSearchChange,
    searchTerm,
    handleSearchSubmit,
    articleCategoryTags,
    selectedTag,
    handleTagClick,
    hasItems,
    paginationOptions,
    sortOptions,
    handleSortChange,
    activeSortOption,
    loading,
    itemsPerPage,
  } = useListingArticles({ articleCategories, articles })

  const headerChildren = useMemo(
    () => (
      <ArticleListHeaderChildren
        page={page}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        handleSearchSubmit={handleSearchSubmit}
        articleCategoryTags={articleCategoryTags}
        selectedTag={selectedTag}
        handleTagClick={handleTagClick}
      />
    ),
    [articleCategoryTags, page, searchTerm, handleTagClick, handleSearchChange, handleSearchSubmit, selectedTag]
  )

  return (
    <Listing
      headerProps={{
        title: page?.title,
        children: headerChildren,
        paddingTop: [12, 12, 18],
        paddingBottom: [8, 11],
        bgColor: ["brand.lightSand"],
      }}
      gridProps={{
        hasItems,
        children: <ArticleListGridChildren filteredItems={filteredItems} />,
        columns: [1, 2, 3],
        columnGap: [10, 4],
        rowGap: [10, 12],
        noItemsLabel: page?.additionalNoArticlesLabel,
        paddingLeft: [0],
        itemsPerPage,
      }}
      paginationProps={{
        computedOptions: paginationOptions,
      }}
      sortOptions={sortOptions}
      handleSortChange={handleSortChange}
      activeSortOption={activeSortOption}
      showBottomBorder
      aboveGridChildren={<ArticleListAboveGridChildren submittedSearchTerm={submittedSearchTerm} />}
      loading={loading}
    />
  )
}

const ArticleList: React.FC<PageProps<Props>> = ({ page, articles, articleCategories }) => {
  return (
    <>
      <ArticleListing page={page} articles={articles} articleCategories={articleCategories} />
      {!!page?.sections?.length && <Sections page={{ content: page?.sections }} />}
    </>
  )
}

export default memo(ArticleList)
