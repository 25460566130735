import { SORT_OPTION_VALUES } from "@app/hooks/useListingFilters"
import { useEffect, useMemo } from "react"
import { useListingFilteringLogic } from "./useListingFilteringLogic"
import { useSanity } from "./useSanity"
import { DEFAULT_RESET_LABEL } from "@components/SelectableTags"

type useListingArticlesArgs = {
  articleCategories: any
  articles: any
}

const sortOptions = [
  { label: "Most Recent", value: SORT_OPTION_VALUES.MOST_RECENT },
  { label: "Least Recent", value: SORT_OPTION_VALUES.LEAST_RECENT },
  { label: "A-Z", value: SORT_OPTION_VALUES.A_TO_Z },
  { label: "Z-A", value: SORT_OPTION_VALUES.Z_TO_A },
]

const ITEMS_PER_PAGE = 18

const projection = [
  `{
    _id,
    title,
    url,
    image {
      alt,
      asset -> {
        url
      }
    },
    categories[][0..0]->{
      title
    },
    handle {
      current
    },
    attributes {
      publishedAt
    }
  }`,
]

const useListingArticles = ({ articleCategories, articles }: useListingArticlesArgs) => {
  const articleItems = useMemo(() => articles?.edges?.map(({ node }: { node: any }) => node), [articles?.edges])

  const { getSanity, sanityItems, loading } = useSanity()

  const articleCategoryTags = useMemo(
    () => Array.from(new Set(articleCategories?.nodes?.map(({ title }: { title: string }) => title))),
    [articleCategories?.nodes]
  )

  const {
    handleSearchSubmit,
    handleSearchChange,
    paginationOptions,
    activeSortOption,
    handleSortChange,
    searchTerm,
    submittedSearchTerm,
    selectedTag,
    handleTagClick,
  } = useListingFilteringLogic({
    itemsPerPage: ITEMS_PER_PAGE,
    sortOptions,
    initialItems: sanityItems?.paginated || articleItems,
    totalItems: sanityItems?.all,
  })

  useEffect(() => {
    const filters =
      selectedTag && selectedTag !== DEFAULT_RESET_LABEL ? `&& count((categories[]->title)[@ match ["*${selectedTag}*"]]) > 0` : ""

    const searchTerm = submittedSearchTerm ? `&& [title, description] match "*${submittedSearchTerm}*"` : ""

    const pagination = `[
      ${(paginationOptions?.humanCurrentPageNumber - 1) * ITEMS_PER_PAGE}...${paginationOptions?.humanCurrentPageNumber * ITEMS_PER_PAGE}
    ]`

    const sorts = [`| order(${activeSortOption?.value})`]

    const baseQuery = [`*[_type == "article" && !(_id in path('drafts.**')) && attributes.publishedAt != null ${searchTerm} ${filters}]`]

    const paginatedQuery = baseQuery.concat(sorts).concat(pagination).concat(projection).join("")

    const allQuery = `count(${baseQuery})`

    const query = `{ 'paginated': ${paginatedQuery}, 'all': ${allQuery} }`

    getSanity(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedSearchTerm, paginationOptions?.humanCurrentPageNumber, activeSortOption, selectedTag])

  return {
    filteredItems: sanityItems?.paginated,
    hasItems: !!sanityItems?.paginated?.length,
    submittedSearchTerm,
    handleSearchChange,
    searchTerm,
    handleSearchSubmit,
    articleCategoryTags,
    selectedTag,
    handleTagClick,
    paginationOptions,
    activeSortOption,
    sortOptions,
    handleSortChange,
    loading,
    itemsPerPage: ITEMS_PER_PAGE,
  }
}

export { useListingArticles }
