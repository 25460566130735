import { graphql, PageProps } from "gatsby"
import Page from "@components/Article/ArticleList"

export type Props = PageProps<GatsbyTypes.PageArticlesQuery, GatsbyTypes.PageArticlesQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageArticles {
    page: sanityPageArticles {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 8 })
      additionalSearchBarPlaceholder
      additionalNoArticlesLabel
      _type
    }
    articles: allSanityArticle(
      filter: { attributes: { publishedAt: { ne: null } } }
      sort: { fields: attributes___publishedAt, order: DESC }
      limit: 18
    ) {
      edges {
        node {
          ...SanityArticleFragment
        }
      }
    }
    articleCategories: allSanityArticleCategory(sort: { fields: title }) {
      nodes {
        _id
        title
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
